* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: #f8fafb;
}
a:active,
a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
b {
    font-weight: normal;
    margin: 0;
}
img {
    border: none;
    vertical-align: middle;
}
a {
    text-decoration: none;
}
i {
    font-style: normal;
}
ul,
p {
    list-style: none;
    margin: 0;
    padding: 0;
}
b {
    font-weight: normal;
}
.ant-card {
    .ant-row.ant-row-space-between {
        .ant-col {
            padding-bottom: 5px;
        }
    }
}

@primary-color: #4187f2;